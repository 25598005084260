import {
    List,
    Datagrid,
    TextField,
    EditButton,
    CreateButton,
    TopToolbar,
    BooleanField,
    SearchInput,
    DeleteButton,
    FilterButton,
} from "react-admin";
import {Chip, Stack} from "@mui/material";

const ListActions = () => {
    return (
        <TopToolbar>
            <FilterButton/>
            <CreateButton label="Добавить ресторан"/>
        </TopToolbar>
    )
}

const QuickFilter = ({ label }) => {
    return <Chip sx={{ marginBottom: 1 }} label={label} />;
};

const restaurantFilters = [
    <SearchInput source="name" placeholder="Введите название" alwaysOn  name="name"/>,
    <QuickFilter source="is_published" label="Статус публикации" defaultValue={true} />,
    <QuickFilter source="is_bookable" label="Доступность бронирования" defaultValue={true} />
]

export const RestaurantList = () => (
    <List actions={<ListActions />} filters={restaurantFilters}>
        <Datagrid rowClick="edit">
            <TextField label="#" source="id" />
            <TextField label="Название" source="name" />
            <BooleanField label="Статус публикации" source="is_published" valueLabelTrue="Да" valueLabelFalse="Нет" />
            <BooleanField label="Доступность бронирования" source="is_bookable" valueLabelTrue="Да" valueLabelFalse="Нет" />
            <EditButton label="Редактировать"/>
            <DeleteButton label="Удалить" />
        </Datagrid>
    </List>
);
