import {
    ArrayInput,
    BooleanInput, Edit, FileField, FileInput,
    ImageField,
    ImageInput, SaveButton, SelectInput,
    SimpleForm, SimpleFormIterator,
    TextInput, Toolbar, useInfiniteGetList
} from "react-admin";
import {Box, Grid, Typography} from '@mui/material';
import {convertFileToBase64} from "../helper";

export const StoryEdit = () => {

    const {
        data: restaurantData,
        hasNextPage: restaurantHasNextPage,
        isFetchingNextPage: restaurantFetchingNextPage,
        fetchNextPage: restaurantFetchNextPage
    } = useInfiniteGetList("restaurant", {
        pagination: { page: 1, perPage: 25 },
        sort: { field: 'name', order: 'ASC' },
        filter: { is_published: true }
    });

    let restaurants = [];

    const getRestaurants = () => {
        if (restaurantData) {
            restaurants = restaurantData.pages
                ?.map((page) => {
                    return page.data.filter((restaurant) => restaurant).map((restaurant) => {
                        return {
                            id: restaurant.id,
                            name: restaurant.name,
                        };
                    });
                }).flat()

            if (restaurantHasNextPage && !restaurantFetchingNextPage) {
                restaurantFetchNextPage();
            }
        }
    }

    getRestaurants()

    const CreateToolbar = () => (
        <Toolbar>
            <SaveButton label="Сохранить" />
        </Toolbar>
    )

    const transform = async (data) => {
        if (data.photo) {
            data.photo.rawFile = await searchRawFile(data.photo);
        }

        if (data?.photos) {
            for (let j = 0; j < data?.photos.length; j++) {
                let photo = data.photos[j]

                data.photos[j].rawFile = await searchRawFile(photo);
            }
        }

        if (data?.content) {
            for (let i = 0; i < data?.content.length; i++) {
                let content = data.content[i];
                if (content.attachment !== null) {
                    data.content[i].attachment.rawFile = await searchRawFile(content.attachment)
                }
            }
        }

        return data;
    }

    const searchRawFile = async (photo) => {
        if (photo == null) {
            return null
        }

        if (photo.src !== undefined) {
            let response = await fetch(photo.src)
            let data = await response.blob()

            if (data.type === 'application/xml') {
                return null
            }

            photo.rawFile = new File([data], '', {
                type: data.type
            })
        }

        if (photo.rawFile instanceof File) {
            return await convertFileToBase64(photo.rawFile);
        }
    }

    return (
        <Edit mutationMode="pessimistic" transform={transform}>
            <SimpleForm toolbar={<CreateToolbar />}>
                <Grid container width="100%">
                    <Grid item>
                        <Typography variant="h6" gutterBottom>
                            Для размещения в хедере
                        </Typography>

                        <Box display="flex">
                            <Box flex={1} mr="0.5em">
                                <ImageInput isRequired source="photo" label="Обложка для превью"
                                            placeholder="Загрузите файл для обложки">
                                    <ImageField title="Обложка" source="src" />
                                </ImageInput>
                            </Box>
                        </Box>

                        <Box display="flex">
                            <Box flex={1} mr="0.5em">
                                <TextInput isRequired source="title" label="Заголовок для превью"/>
                            </Box>
                        </Box>

                        <Box display="flex">
                            <BooleanInput name="is_published" source="is_published" label="Опубликовано"/>
                        </Box>

                        <Typography variant="h6" gutterBottom>
                            Контент сторис
                        </Typography>

                        <Box display="flex">
                            <ArrayInput source="content" label="Контент сторис">
                                <SimpleFormIterator inline>
                                    <TextInput source="title" label="Заголовок" />
                                    <TextInput source="subtitle" label="Подзаголовок" />
                                    <TextInput source="description" label="Описание" />
                                    <FileInput source="attachment" label="Фото/видео" isRequired={true}>
                                        <FileField title="Фото/видео" source="src" />
                                    </FileInput>
                                    <BooleanInput source="is_published" label="Опубликован" />
                                    <SelectInput source="restaurant_id" label="Выберите ресторан" choices={restaurants} />
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Box>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    )
};
