import {
    Admin,
    Resource,
} from "react-admin";
import myDataProvider from "./dataProvider";
import { authProvider } from "./authProvider";
import { MyLayout } from "./components/menu/Layout";
import {RestaurantList} from "./restaurants/RestaurantList";
import {RestaurantEdit} from "./restaurants/RestaurantEdit";
import {RestaurantCreate} from "./restaurants/RestaurantCreate";
import {FilterList} from "./filters/FilterList";
import {FilterEdit} from "./filters/FilterEdit";
import {FilterCreate} from "./filters/FilterCreate";
import {StoryList} from "./stories/StoryList";
import {StoryEdit} from "./stories/StoryEdit";
import {StoryCreate} from "./stories/StoryCreate";
import {ReviewList} from "./review/ReviewList";
import {ReviewEdit} from "./review/ReviewEdit";
import {ReviewCreate} from "./review/ReviewCreate";
import {NewsCreate} from "./news/NewsCreate";
import {NewsList} from "./news/NewsList";
import {NewsEdit} from "./news/NewsEdit";
import {SelectionList} from "./selection/SelectionList";
import {SelectionEdit} from "./selection/SelectionEdit";
import {SelectionCreate} from "./selection/SelectionCreate";
import {MainList} from "./main/MainList";
import {MainEdit} from "./main/MainEdit";
import {MainCreate} from "./main/MainCreate";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const dataProvider = myDataProvider(`${API_ENDPOINT}/api/admin`);

const App = () => {
    return (
        <Admin
            layout={MyLayout}
            dataProvider={dataProvider}
            authProvider={authProvider}
            requireAuth
        >
            <Resource
                options={{label: 'Главная'}}
                name="main"
                recordRepresentation="main"
                list={MainEdit}
                edit={MainEdit}
                create={MainCreate}
            />

            <Resource
                options={{label: 'Рестораны'}}
                name="restaurant"
                recordRepresentation="restaurant"
                list={RestaurantList}
                edit={RestaurantEdit}
                create={RestaurantCreate}
            />

            <Resource
                options={{label: 'Сторисы'}}
                recordRepresentation="story"
                name="story"
                list={StoryList}
                edit={StoryEdit}
                create={StoryCreate}
            />

            <Resource
                options={{label: 'Обзоры'}}
                recordRepresentation="review"
                name="review"
                list={ReviewList}
                edit={ReviewEdit}
                create={ReviewCreate}
            />

            <Resource
                options={{label: 'Новости'}}
                recordRepresentation="news"
                name="news"
                list={NewsList}
                edit={NewsEdit}
                create={NewsCreate}
                />

            <Resource
                options={{label: 'Подборки ресторанов'}}
                recordRepresentation="selection"
                name="selection"
                list={SelectionList}
                edit={SelectionEdit}
                create={SelectionCreate}
            />

            <Resource
                options={{label: 'Фильтры'}}
                name="filter"
                recordRepresentation="filter"
                list={FilterList}
                edit={FilterEdit}
                create={FilterCreate}
            />
        </Admin>
    );
};

export default App;
