const JWTManager = () => {
    let refreshEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/api/auth/refresh`;

    const setRefreshTokenEndpoint = (endpoint) => (refreshEndpoint = endpoint);
    const getRefreshTokenEndpoint = () => refreshEndpoint;

    const getToken = () => JSON.parse(localStorage.getItem('accessToken'));

    const setToken = (token) => localStorage.setItem('accessToken', JSON.stringify(token));

    const getRoles = () => localStorage.getItem('userRoles');
    const setRoles = (roles) => localStorage.setItem('userRoles', roles);

    const eraseToken = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userRoles');
        return true;
    };

    return {
        eraseToken,
        getToken,
        setRefreshTokenEndpoint,
        getRefreshTokenEndpoint,
        setToken,
        getRoles,
        setRoles
    };
};

export default JWTManager();
