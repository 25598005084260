import React from 'react';
import { NumberInput } from 'react-admin';

export const CustomNumberInput = (props) => {
  const handleKeyDown = (event) => {
    const allow =
      event.key === 'Backspace' ||
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowRight' ||
      event.key === 'Delete';
    
    if (!/^\d$/.test(event.key) && !allow) {
      event.preventDefault();
    }
  };

  return <NumberInput {...props} onKeyDown={handleKeyDown} />;
};