import jwtInterceptor from "./axiosRequest";
import {HttpError} from "react-admin";

const dataProvider = (apiUrl) => {

  return {
    getList: (resource, params) => {
      return jwtInterceptor.get(`${apiUrl}/${resource}`, {
        params: params
      })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          return Promise.reject(error);
        });
    },
    getOne: (resource, params) => {
      return jwtInterceptor.get(`${apiUrl}/${resource}/${params.id}`, {
        params: params
      })
        .then((response) => {
            response.data.photos = response.data.photos?.map((photo) => {
                photo.src = photo.file.url
                return photo;
            })
          return response;
        })
        .catch((error) => {
          console.log(error);
          return Promise.reject(error);
        });
    },
    getMany: (resource, params) => {
      return jwtInterceptor
        .get(`${apiUrl}/${resource}?ids=${JSON.stringify({ id: params.ids })}`,)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getManyReference: () => Promise.reject(),
    update: (resource, params) => {
      return jwtInterceptor
        .patch(`${apiUrl}/${resource}/update/${params.id}`, params)
        .then((response) => {
          return response;
        })
        .catch((error) => {
            console.log(error)
            throw new HttpError(error.response.data.message, error.response.status, error.response.data.body)
        });
    },
    updateMany: () => {
      console.log('updateMany');
      Promise.reject()
    },
    create: (resource, params) => {
      return jwtInterceptor
        .post(`${apiUrl}/${resource}/create`, params)
        .then((response) => {
          return response;
        })
        .catch((error) => {
            throw new HttpError(error.response.data.message, error.response.status, error.response.data.body)
        });
    },
    delete: (resource, params) => {
      return jwtInterceptor
        .delete(`${apiUrl}/${resource}/${params.id}`)
        .then((response) => {
          return response;
        })
        .catch((error) => {
            return Promise.reject(new Error(error.response.data.message))
        });
    },
    deleteMany: (resource, params) => {
      return jwtInterceptor
        .delete(
          `${apiUrl}/${resource}?filter=${JSON.stringify({ id: params.ids })}`,
        )
        .then((response) => {
          return response;
        })
        .catch((error) => {
            return Promise.reject(new Error(error.response.data.message))
        });
    },
    getListBenefit: (resource, params) => {
      return jwtInterceptor.get(`${apiUrl}/${resource}/${params.id}`)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCian: (resource) => {
      return jwtInterceptor.get(`${apiUrl}/${resource}`)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
};

export default dataProvider;
