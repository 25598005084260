import {List, Datagrid, TextField, EditButton, DeleteButton, CreateButton, TopToolbar} from "react-admin";

const ListActions = () => {
    return (
        <TopToolbar>
            <CreateButton label="Создать" />
        </TopToolbar>
    )
}

export const FilterList = () => (
    <List exporter={false} actions={<ListActions />} sort={{}}>
        <Datagrid>
            <TextField label="#" source="id" />
            <TextField label="Название" source="name" />
            <EditButton label="Редактировать"/>
            <DeleteButton label="Удалить" />
        </Datagrid>
    </List>
);
