import {
    ArrayInput,
    BooleanInput,
    CheckboxGroupInput,
    Create, FormDataConsumer,
    ImageField,
    ImageInput,
    SaveButton, SelectArrayInput, SelectInput,
    SimpleForm, SimpleFormIterator,
    TextInput, Toolbar, useGetList, useInfiniteGetList
} from "react-admin";
import {Box, Grid, Typography} from '@mui/material';
import {convertFileToBase64} from "../helper";
import { CustomNumberInput } from "../components/CustomNumberInput";

export const RestaurantCreate = () => {
    const { data: nominationsData } = useGetList('restaurant/nominations')

    const { data: filtersData } = useInfiniteGetList('filter')

    const { data: filterWithValuesData } = useInfiniteGetList('filter/values')

    let filterValues = {};

    let nominations = []
    let filters = []

    if (nominationsData) {
        nominations = nominationsData.map((item) => {
            return {
                id: item.id,
                name: item.name
            }
        })
    }

    if (filtersData) {
        filters = filtersData.pages.map((page) => {
            return page.data.map((filter) => {
                return {
                    id: filter.id,
                    name: filter.name
                }
            })
        }).flat()
    }

    if (filterWithValuesData) {
        filterValues = filterWithValuesData.pages.map((page) => {
            return page.data.map((filter) => filter)
        }).flat()
    }

    const getFilterValuesById = (id) => {
        return filterValues.find((item) => item.id === id).values;
    }

    const transform = async (data) => {
        if (!!data?.selectedFilterValues) {
            for (const item of data.selectedFilterValues) {
                if (!Array.isArray(item.filterValue) ) {
                    item.filterValue = [item.filterValue];
                }
            }
        }

        if (!!data?.photo) {
            data.photo.rawFile = await searchRawFile(data.photo);
        }

        if (data?.photos !== undefined) {
            for (let j = 0; j < data?.photos.length; j++) {
                let photo = data.photos[j]

                data.photos[j].rawFile = await searchRawFile(photo);
            }
        }

        if (data?.dishes !== undefined) {
            for (let i = 0; i < data?.dishes.length; i++) {
                let dish = data.dishes[i];
                if (dish.photo !== null) {
                    data.dishes[i].photo.rawFile = await searchRawFile(dish.photo)
                }
            }
        }

        data.nominations = data.selectedNominations;

        return data;
    }

    const searchRawFile = async (photo) => {
        if (!!photo?.src) {
            let response = await fetch(photo.src)
            let data = await response.blob()

            if (data.type === 'application/xml') {
                return null
            }

            photo.rawFile = new File([data], '', {
                type: data.type
            })
        }

        if (photo != null) {
            if (photo?.rawFile instanceof File) {
                return await convertFileToBase64(photo.rawFile);
            }
        }
    }

    const CreateToolbar = () => (
        <Toolbar>
            <SaveButton label="Создать" />
        </Toolbar>
    )

    return (
        <Create title="Создание ресторана" transform={transform}>
            <SimpleForm toolbar={<CreateToolbar />}>
                <Grid container width="100%">
                    <Grid item>
                        <Typography variant="h6" gutterBottom>
                            Общая информация
                        </Typography>

                        <Box display="flex">
                            <ImageInput isRequired source="photo" label="Обложка"
                                        placeholder="Загрузите файл для обложки" name="photo">
                                <ImageField title="Обложка" source="src" />
                            </ImageInput>
                        </Box>

                        <Box display="flex">
                            <Box flex={1} mr="0.5em">
                                <TextInput isRequired name="name" source="name" label="Название ресторана"/>
                            </Box>
                            <Box flex={1} mr="0.5em">
                                <TextInput name="subtitle" source="subtitle" label="Подзаголовок"/>
                            </Box>
                            <Box flex={1} mr="0.5em">
                                <TextInput isRequired name="description" source="description" label="Описание"/>
                            </Box>
                        </Box>

                        <Box display="flex">
                            <Box flex={1} mr="0.5em">
                                <TextInput
                                    isRequired
                                    source="slug"
                                    label="URL"
                                />
                            </Box>
                        </Box>

                        <Box display="flex">
                            <CheckboxGroupInput source="selectedNominations" label="Номинации" choices={nominations}
                                                name="selectedNominations"/>
                        </Box>
                        <Box display="flex">
                            <BooleanInput name="is_bookable" source="is_bookable" label="Доступен для бронирования"/>
                            <BooleanInput name="is_published" source="is_published" label="Опубликовано"/>
                        </Box>
                        <ImageInput isRequired name="photos" source="photos" label="Галерея фото" placeholder="Перетащите или загрузите фото" multiple={true}>
                            <ImageField source="src"/>
                        </ImageInput>
                    </Grid>
                </Grid>

                <Grid container width="100%">
                    <Grid item>
                        <Typography variant="h6" gutterBottom>
                            Информация для фильтра
                        </Typography>

                        <Box display="flex">
                            <ArrayInput label="Фильтры ресторана" source="selectedFilterValues">
                                <SimpleFormIterator inline>
                                    <SelectInput label="Выберите фильтр" source="filterType" choices={filters} />
                                    <FormDataConsumer>
                                        {({formData, // The whole form data
                                              scopedFormData, // The data for this item of the ArrayInput
                                              getSource, // A function to get the valid source inside an ArrayInput
                                              ...rest}) => {
                                            if (scopedFormData && getSource && scopedFormData.filterType) {
                                                console.log("scopedFormData.filterType", scopedFormData);
                                                if (scopedFormData.filterType === 3 ||
                                                    scopedFormData.filterType === 29 ||
                                                    scopedFormData.filterType === 28
                                                ) {
                                                    return (<SelectInput label="Выберите значение" source={getSource("filterValue")} choices={getFilterValuesById(scopedFormData.filterType)}/>)
                                                } else {
                                                    return (<SelectArrayInput label="Выберите значение" source={getSource("filterValue")} choices={getFilterValuesById(scopedFormData.filterType)}/>)
                                                }

                                            } else {
                                                return null
                                            }
                                        }}
                                    </FormDataConsumer>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container width="100%">
                    <Grid item>
                        <Typography variant="h6" gutterBottom>
                            Контакты
                        </Typography>
                        <Box display="flex">
                            <Box flex={1} mr="0.5em">
                                <TextInput isRequired name="address" source="address" label="Адрес ресторана"/>
                            </Box>
                            <Box flex={1} mr="0.5em">
                                <TextInput isRequired name="coordinates" source="coordinates" label="Координаты"/>
                            </Box>
                            <Box flex={1} mr="0.5em">
                                <TextInput isRequired name="schedule" source="schedule" label="График работы" multiline={true}/>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container width="100%">
                    <Grid item>
                        <Typography variant="h6" gutterBottom>
                            Блюда
                        </Typography>
                        <ArrayInput label="" name="Блюда" source="dishes">
                            <SimpleFormIterator inline>
                                <TextInput label="Название блюда" source="name" />
                                <CustomNumberInput label="Цена блюда" source="price" />
                                <ImageInput source="photo" label="Фото"
                                            placeholder="Загрузите файл для фото">
                                    <ImageField title="Фото" source="src" />
                                </ImageInput>
                            </SimpleFormIterator>
                        </ArrayInput>
                        <Typography variant="h6" gutterBottom>
                            Меню
                        </Typography>
                        <ArrayInput label="" name="Меню" source="menus">
                            <SimpleFormIterator inline>
                                <TextInput label="Название меню" source="name" />
                                <TextInput label="Период действия" source="period" />
                                <TextInput label="Ссылка" source="link" />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Grid>
                </Grid>

                <Grid container width="100%">
                    <Grid item>
                        <Typography variant="h6" gutterBottom>
                            SEO
                        </Typography>
                        <Box display="flex">
                            <TextInput multiline={true} name="seo_title" source="seo_title" label="Заголовок страницы"/>
                        </Box>
                        <Box display="flex">
                            <TextInput multiline={true} name="seo_description" source="seo_description" label="Описание"/>
                        </Box>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
};
