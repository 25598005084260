import {useMemo}   from 'react';
import {useState}  from 'react';
import {useEffect} from 'react';
import {
    BooleanInput,
    CheckboxGroupInput,
    Create,
    ImageField,
    ImageInput, SaveButton, SelectArrayInput, SelectInput,
    SimpleForm,
    TextInput, Toolbar, useInfiniteGetList
}                  from "react-admin";
import {Box, Grid, Typography} from '@mui/material';
import {transform}             from "../helper";
import {RichTextInput}         from "ra-input-rich-text";
import { CustomDateTime }      from "../components/CustomDateTime";

export const NewsCreate = () => {
    const { data: categoriesData } = useInfiniteGetList('content/categories')
    const options = [
        { id: "lighting", name: "Молния" },
        { id: "fire", name: "Огонь" },
        { id: "camera", name: "Фотоаппарат" },
        { id: "hash", name: "Решетка" },
        { id: "star", name: "Звезда" },
        { id: "glass", name: "Стакан" },
        { id: "coffee", name: "Кофе" },
        { id: "heart", name: "Сердце" },
        { id: "bookmark", name: "Закладка" },
        { id: "tick", name: "Галочка" },
        { id: "point_on_map", name: "Точка на карте" },
        { id: "wineglass122", name: "Фужер" },
        { id: "roll142", name: "Суши" },
        { id: "taco", name: "Тако" },
        { id: "fork_and_knife", name: "Вилка и нож" },
        { id: "cake", name: "Торт" },
        { id: "pizza", name: "Пицца" },
        { id: "roll152", name: "Ролл" },
    ];

    const {
        data: restaurantData,
        hasNextPage: restaurantHasNextPage,
        isFetchingNextPage: restaurantFetchingNextPage,
        fetchNextPage: restaurantFetchNextPage
    } = useInfiniteGetList("restaurant", {
        pagination: { page: 1, perPage: 25 },
        sort: { field: 'name', order: 'ASC' }
    });

    let categories = [];

    if (categoriesData) {
        categories = categoriesData.pages
            .map((page) => {
                return page.data.map((item) => {
                    return {
                        id: item.id,
                        name: item.name
                    }
                })
            }).flat()
    }

    let restaurants = [];

    const getRestaurants = () => {
        if (restaurantData) {
            restaurants = restaurantData.pages
                ?.map((page) => {
                    return page.data.filter((restaurant) => restaurant).map((restaurant) => {
                        return {
                            id: restaurant.id,
                            name: restaurant.name,
                        };
                    });
                }).flat()

            if (restaurantHasNextPage && !restaurantFetchingNextPage) {
                restaurantFetchNextPage();
            }
        }
    }

    getRestaurants()

    const CreateToolbar = () => (
        <Toolbar>
            <SaveButton label="Создать" />
        </Toolbar>
    )

    return (
        <Create title="Создание новости" transform={transform}>
            <SimpleForm toolbar={<CreateToolbar />}>
                <Grid container width="100%">
                    <Grid item>
                        <Typography variant="h6" gutterBottom>
                            Общая информация
                        </Typography>

                        <Box display="flex">
                            <Box flex={1} mr="0.5em">
                                <TextInput isRequired name="title" source="title" label="Заголовок новости"/>
                            </Box>
                        </Box>

                        <Box display="flex">
                            <ImageInput isRequired source="photo_cover" label="Обложка"
                                        placeholder="Загрузите файл для обложки" name="photo_cover">
                                <ImageField title="Обложка" source="src" />
                            </ImageInput>
                        </Box>

                        <Box display="flex">
                            <SelectInput
                                isRequired={true}
                                label="Дополнительная обложка"
                                source="additional_cover"
                                choices={options}
                            />
                        </Box>

                        <Box display="flex">
                            <CheckboxGroupInput source="content_categories" label="Категории контента" choices={categories}
                                                name="content_categories" field="content_categories"/>
                        </Box>

                        {/* <Box display="flex">
                            <DateTimeInput isRequired={true} name="publication_time" source="publication_time" label="Дата и время публикации"/>
                        </Box> */}

                        <Box>
                            <CustomDateTime label="Дата и время публикации*:    " source="publication_time" />
                        </Box>

                        <Box display="flex">
                            <Box flex={1} mr="0.5em">
                                <TextInput
                                    isRequired
                                    source="slug"
                                    label="URL"
                                />
                            </Box>
                        </Box>

                        <Box display="flex">
                            <SelectArrayInput
                                source="restaurant_ids"
                                label="Выберите список ресторанов"
                                choices={restaurants}
                            />
                        </Box>

                        <BooleanInput name="is_published" source="is_published" label="Статус публикации" />
                        <BooleanInput name="is_old" source="is_old" label="Со старого сайта?" />
                    </Grid>
                </Grid>

                <Grid container width="100%">
                    <Grid item>
                        <Typography variant="h6" gutterBottom>
                            Блок контент новости
                        </Typography>

                        <Box display="flex">
                            <Box flex={1} mr="0.5em">
                                <TextInput
                                    source="content_title"
                                    label="Заголовок контента"
                                />
                            </Box>
                        </Box>

                        <Box display="flex">
                            <RichTextInput
                                isRequired={true}
                                multiline={true}
                                sx={{ maxWidth: "1000px", maxHeight: "500px", "overflow-y": "auto"}}
                                name="content_text"
                                source="content_text"
                                label="Текст содержания новости"
                            />
                        </Box>
                        <Box display="flex">
                            <ImageInput name="content_photo" source="content_photo" label="Основная фотография" placeholder="Перетащите или загрузите фото">
                                <ImageField source="src"/>
                            </ImageInput>
                        </Box>
                        <Box display="flex">
                            <TextInput name="photo_caption" source="photo_caption" label="Подпись к фото"/>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container width="100%">
                    <Grid item>
                        <Typography variant="h6" gutterBottom>
                            SEO
                        </Typography>
                        <Box display="flex">
                            <TextInput multiline={true} name="seo_title" source="seo_title" label="Заголовок страницы"/>
                        </Box>
                        <Box display="flex">
                            <TextInput multiline={true} name="seo_description" source="seo_description" label="Описание"/>
                        </Box>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
};
