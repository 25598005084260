import {
    List,
    Datagrid,
    TextField,
    EditButton,
    CreateButton,
    DeleteButton,
    TopToolbar,
    BooleanField,
} from "react-admin";

const ListActions = () => {
    return (
        <TopToolbar>
            <CreateButton label="Создать" />
        </TopToolbar>
    );
};

export const ReviewList = () => (
    <List
        exporter={false}
        actions={<ListActions />}
        sort={{ field: "publication_time", order: "DESC" }}
    >
        <Datagrid>
            <TextField label="#" source="id" />
            <TextField label="Название ресторана" source="restaurant.name" />
            <BooleanField label="Опубликовано" source="is_published" />
            <TextField label="Дата публикации" source="publication_time" />
            <EditButton label="Редактировать" />
            <DeleteButton label="Удалить" />
        </Datagrid>
    </List>
);
