const onWheelNumberInput = (e) => {
    e.target.blur()
}

const convertFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

const searchRawFile = async (photo) => {
    if (photo.src !== undefined) {
        let response = await fetch(photo.src)
        let data = await response.blob()

        if (data.type === 'application/xml') {
            return null
        }

        photo.rawFile = new File([data], '', {
            type: data.type
        })
    }

    if (photo.rawFile instanceof File) {
        return await convertFileToBase64(photo.rawFile);
    }
}

const snakeToCamel = str =>
    str.toLowerCase().replace(/([-_][a-z])/g, group =>
        group
            .toUpperCase()
            .replace('-', '')
            .replace('_', '')
    );

const transform = async (data, previousData) => {
    if (data.photo_cover) {
        data.photo_cover.rawFile = await searchRawFile(data.photo_cover);
    }

    if (data.content_photo) {
        data.content_photo.rawFile = await searchRawFile(data.content_photo);
    }

    if (data?.photos !== undefined) {
        for (let j = 0; j < data?.photos.length; j++) {
            let photo = data.photos[j]

            data.photos[j].rawFile = await searchRawFile(photo);
        }
    }

    return data;
}

export {
    onWheelNumberInput,
    convertFileToBase64,
    transform,
    searchRawFile
}
