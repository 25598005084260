import {
    ArrayInput,
    BooleanInput, Button,
    CheckboxGroupInput, Datagrid,
    DeleteButton,
    Edit,
    ImageField,
    ImageInput, NumberField, NumberInput, ReferenceManyField, SaveButton, SelectInput,
    SimpleForm, SimpleFormIterator, TextField,
    TextInput, Toolbar, useGetList, useInfiniteGetList
} from 'react-admin';
import {Box, Grid, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import axiosRequest from "../axiosRequest";
import {convertFileToBase64} from "../helper";
import * as PropTypes from "prop-types";

const EditToolbar = () => (
    <Toolbar>
        <SaveButton mutationMode="pessimistic" label="Сохранить" />
        <DeleteButton mutationMode="pessimistic" label="Удалить" />
    </Toolbar>
)

export const FilterEdit = () => {
    const { data } = useGetList('filter/options')

    let options = []

    if (data) {
        options = data.map((item) => {
            return {
                id: item.name,
                name: item.value
            }
        })
    }

    return (
        <Edit mutationMode="pessimistic">
            <SimpleForm toolbar={<EditToolbar />}>
                <Grid container width="100%">
                    <Grid item>
                        <Typography variant="h6" gutterBottom>
                            Данные фильтра
                        </Typography>

                        <Box display="flex">
                            <Box flex={1} mr="0.5em">
                                <TextInput isRequired name="name" source="name" label="Название фильтра"/>
                            </Box>
                        </Box>

                        <Box display="flex">
                            <ArrayInput source="values" label="Значения фильтра">
                                <SimpleFormIterator inline>
                                    <TextInput source="id" style={{display: "none"}} />
                                    <TextInput source="name" label="Название значения фильтра" />
                                    <ArrayInput source="options" label="Опции значения">
                                        <SimpleFormIterator inline>
                                            <SelectInput source="name" label="Тип опции" choices={options} />
                                            <TextInput source="value" label="Значение" />
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Box>

                        <Box display="flex">
                            <BooleanInput name="is_fixable" source="is_fixable" label="Фиксированный фильтр"/>
                        </Box>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    )
};
