import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles/CustomDatePicker.css";
import { useInput } from 'react-admin';

export const CustomDateTime = ({ label, source }) => {
    const { id, field, fieldState } = useInput({ source });
    let err = false;
    
    let value;
    if (!!field?.value?.date) {
        value = new Date(field.value.date);
    } else if (!!field?.value) {
        value = new Date(field.value);
    } else {
        value = null;
    } 

    if (value !== null && value.getFullYear) {
        if (value.getFullYear() < 2000 || value.getFullYear() > 2050) {
            err = true
        }
    }
    
    return (
        <div>
        <label htmlFor={id}>
            {label}
            <DatePicker
                {...field}
                selected={value}
                dateFormat="yyyy-MM-dd HH:mm"
                timeFormat="HH:mm"
                showTimeSelect
            />

            {err && <span style={{ color: 'red' }}>   Некорректный формат</span>}
        </label>
        </div>

    );
};