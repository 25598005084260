import axios from "axios";
import JwtManager from "./JwtManager";
import { addRefreshAuthToAuthProvider } from 'react-admin';
import { refreshAuth } from "./refreshAuth";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + "/api";

const authAxios = axios.create();
authAxios.interceptors.request.use((config) => {
    const token = JwtManager.getToken()
    if (token !== null) {
        config.headers['Authorization'] = `bearer ${token.access_token}`;
    }
    return config;
});

const myAuthProvider = {
    login: ({ username, password }) => {
        return authAxios.post(`${API_ENDPOINT}/auth/login`, {
            name: username,
            password: password
        })
            .then((result)=> {
                if (result.status < 200 || result.status >= 300) {
                    throw new Error('Неверный логин или пароль');
                }
                return result;
            })
            .then((result) => {
                JwtManager.setToken({
                    access_token: result.data.access_token,
                    expires_in: (Date.now() / 1000) + result.data.expires_in
                })

                return Promise.resolve()
            })
            .catch((error) => {
                return Promise.reject()
            })
    },
    logout: () => {
        authAxios.post(`${API_ENDPOINT}/auth/logout`)
            .catch((error) => {})

        JwtManager.eraseToken()
        return Promise.resolve();
    },
    checkAuth: () => localStorage.getItem('accessToken') ? Promise.resolve() : Promise.reject(),
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            JwtManager.eraseToken()
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getIdentity: () => {
        authAxios.post(`${API_ENDPOINT}/auth/me`)
            .then((result) => {
                return Promise.resolve({
                    id: result.id,
                    fullName: result.name,
                })
            })
            .catch((error) => {
                return Promise.reject();
            })
    },
    getPermissions: () => Promise.resolve(''),
};

export const authProvider = addRefreshAuthToAuthProvider(myAuthProvider, refreshAuth);
