import {
    ArrayInput,
    BooleanInput,
    Create,
    ImageField,
    ImageInput,
    SaveButton,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    Toolbar,
    useInfiniteGetList,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { Box, Grid, Typography } from "@mui/material";
import { convertFileToBase64 } from "../helper";
import { CustomDateTime } from "../components/CustomDateTime";
import { CustomNumberInput } from "../components/CustomNumberInput";

export const ReviewCreate = () => {
    const options = [
        { id: "lighting", name: "Молния" },
        { id: "fire", name: "Огонь" },
        { id: "camera", name: "Фотоаппарат" },
        { id: "hash", name: "Решетка" },
        { id: "star", name: "Звезда" },
        { id: "glass", name: "Стакан" },
        { id: "coffee", name: "Кофе" },
        { id: "heart", name: "Сердце" },
        { id: "bookmark", name: "Закладка" },
        { id: "tick", name: "Галочка" },
        { id: "point_on_map", name: "Точка на карте" },
        { id: "wineglass122", name: "Фужер" },
        { id: "roll142", name: "Суши" },
        { id: "taco", name: "Тако" },
        { id: "fork_and_knife", name: "Вилка и нож" },
        { id: "cake", name: "Торт" },
        { id: "pizza", name: "Пицца" },
        { id: "roll152", name: "Ролл" },
    ];

    let restaurants = [];

    const {
        data: restaurantData,
        hasNextPage: restaurantHasNextPage,
        isFetchingNextPage: restaurantFetchingNextPage,
        fetchNextPage: restaurantFetchNextPage
    } = useInfiniteGetList("restaurant", {
        pagination: { page: 1, perPage: 25 },
        sort: { field: 'name', order: 'ASC' }
    });

    const getRestaurants = () => {
        if (restaurantData) {
            restaurants = restaurantData.pages
                ?.map((page) => {
                    return page.data.filter((restaurant) => restaurant).map((restaurant) => {
                        return {
                            id: restaurant.id,
                            name: restaurant.name,
                        };
                    });
                }).flat()

            if (restaurantHasNextPage && !restaurantFetchingNextPage) {
                restaurantFetchNextPage();
            }
        }
    }

    getRestaurants()

    const CreateToolbar = () => (
        <Toolbar>
            <SaveButton label="Создать" />
        </Toolbar>
    );

    const transform = async (data) => {
        if (!!data?.photo_cover) {
            data.photo_cover.rawFile = await searchRawFile(data.photo_cover);
        }

        if (!!data?.photo_menu) {
            data.photo_menu.rawFile = await searchRawFile(data.photo_menu);
        }

        if (!!data?.photos_interior) {
            for (let index = 0; index < data.photos_interior.length; index++) {
                data.photos_interior[index].rawFile = await searchRawFile(
                    data.photos_interior[index]
                );
            }
        }

        if (data?.dishes !== undefined) {
            for (let i = 0; i < data?.dishes.length; i++) {
                let dish = data.dishes[i];
                if (dish.photo !== null) {
                    data.dishes[i].photo.rawFile = await searchRawFile(
                        dish.photo
                    );
                }
            }
        }

        return data;
    };

    const searchRawFile = async (photo) => {
        if (!!photo?.src) {
            let response = await fetch(photo.src);
            let data = await response.blob();

            if (data.type === "application/xml") {
                return null;
            }

            photo.rawFile = new File([data], "", {
                type: data.type,
            });
        }

        if (!!photo?.rawFile && photo.rawFile instanceof File) {
            return await convertFileToBase64(photo.rawFile);
        }
    };

    return (
        <Create title="Создание обзора" transform={transform}>
            <SimpleForm toolbar={<CreateToolbar />}>
                <Grid container width="100%">
                    <Grid item>
                        <Typography variant="h6" gutterBottom>
                            Общая информация
                        </Typography>

                        <Box display="inline">
                            <SelectInput
                                isRequired
                                source="restaurant_id"
                                label="Выберите ресторан"
                                choices={restaurants}
                                sx={{ marginRight: "20px" }}
                            />
                            <TextInput isRequired source="title" label="Подзаголовок" />
                            <ImageInput
                                isRequired
                                source="photo_cover"
                                label="Обложка"
                                placeholder="Загрузите файл для обложки"
                            >
                                <ImageField title="Обложка" source="src" />
                            </ImageInput>
                            <SelectInput
                                isRequired
                                label="Дополнительная обложка"
                                source="additional_cover"
                                sx={{ marginRight: "20px" }}
                                choices={options}
                            />
                            {/* <DateTimeInput
                                label="Дата и время публикации"
                                isRequired
                                source="publication_time"
                            /> */}
                            <Box display="flex">
                                <CustomDateTime label="Дата и время публикации*:    " source="publication_time" />
                            </Box>


                            {/* <Box display="flex">
                                <Box flex={1} mr="0.5em">
                                    <TextInput
                                        source="content_title"
                                        label="Заголовок контента"
                                    />
                                </Box>
                            </Box> */}

                            <Box display="flex">
                                <BooleanInput
                                    name="is_published"
                                    source="is_published"
                                    label="Опубликовано"
                                />
                            </Box>
                            <TextInput source="author" label="Автор обзора" />
                            <Box display="flex">
                                <Box flex={1} mr="0.5em">
                                    <TextInput
                                        isRequired
                                        source="slug"
                                        label="URL"
                                    />
                                </Box>
                            </Box>
                        </Box>

                        <Typography variant="h6" gutterBottom>
                            О заведении
                        </Typography>

                        <Box display="flex" flexDirection="column">
                            <TextInput
                                multiline
                                fullWidth
                                source="restorators"
                                label="Рестораторы"
                                sx={{ marginRight: "20px" }}
                            />
                            <TextInput
                                multiline
                                fullWidth
                                source="description_chiefs"
                                label="Шеф-повара"
                                sx={{ marginRight: "20px" }}
                            />
                            <RichTextInput
                                sx={{ maxWidth: "1000px", maxHeight: "500px", "overflow-y": "auto"}}
                                source="content_text"
                                type="text"
                                label="Текст"
                            ></RichTextInput>
                        </Box>

                        <Typography variant="h6" gutterBottom>
                            Интерьер
                        </Typography>

                        <Box display="flex" flexDirection="column">
                            <RichTextInput
                                sx={{ maxWidth: "1000px", maxHeight: "500px", "overflow-y": "auto"}}
                                source="content_text_interior1"
                                type="text"
                                label="Текст"
                            ></RichTextInput>
                            <ImageInput
                                source="photos_interior"
                                label="Галерея фото"
                                placeholder="Перетащите или загрузите фото"
                                multiple={true}
                            >
                                <ImageField source="src" title="Галерея фото" />
                            </ImageInput>

                            <TextInput
                                source="caption_interior"
                                label="Комментарий к фото"
                            />
                            <RichTextInput
                                sx={{ maxWidth: "1000px", maxHeight: "500px", "overflow-y": "auto"}}
                                source="content_text_interior2"
                                type="text"
                                label="Текст"
                            ></RichTextInput>
                        </Box>

                        <Typography variant="h6" gutterBottom>
                            Меню
                        </Typography>

                        <Box display="flex" flexDirection="column">
                            <ImageInput
                                source="photo_menu"
                                label="Обложка"
                                placeholder="Загрузите файл для обложки"
                            >
                                <ImageField title="Обложка" source="src" />
                            </ImageInput>
                            <TextInput
                                source="caption_menu"
                                label="Комментарий к фото"
                            />
                            <RichTextInput
                                sx={{ maxWidth: "1000px", maxHeight: "500px", "overflow-y": "auto"}}
                                source="content_text_menu1"
                                type="text"
                                label="Текст"
                            ></RichTextInput>
                            <ArrayInput label="Блюда" source="dishes">
                                <SimpleFormIterator inline>
                                    <TextInput
                                        label="Название блюда"
                                        source="name"
                                    />
                                    <CustomNumberInput
                                        label="Цена блюда"
                                        source="price"
                                    />
                                    <ImageInput
                                        multiple={false}
                                        source="photo"
                                        label="Фото"
                                        placeholder="Загрузите файл для фото"
                                    >
                                        <ImageField
                                            title="Фото блюда"
                                            source="src"
                                        />
                                    </ImageInput>
                                </SimpleFormIterator>
                            </ArrayInput>
                            <RichTextInput
                                sx={{ maxWidth: "1000px", maxHeight: "500px", "overflow-y": "auto"}}
                                source="content_text_menu2"
                                type="text"
                                label="Текст"
                            ></RichTextInput>
                        </Box>

                        <Typography variant="h6" gutterBottom>
                            Напитки
                        </Typography>

                        <Box display="flex" flexDirection="column">
                            <RichTextInput
                                sx={{ maxWidth: "1000px", maxHeight: "500px", "overflow-y": "auto"}}
                                source="content_text_drinks1"
                                type="text"
                                label="Текст"
                            ></RichTextInput>

                            <ArrayInput label="Напитки" source="dishes2">
                                <SimpleFormIterator inline>
                                    <TextInput
                                        label="Название напитка"
                                        source="name"
                                    />
                                    <CustomNumberInput
                                        label="Цена напитка"
                                        source="price"
                                    />
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Box>

                        <Typography variant="h6" gutterBottom>
                            SEO
                        </Typography>

                        <Box display="flex" flexDirection="column">
                            <TextInput source="seo_title" label="Заголовок" />
                            <TextInput
                                source="seo_description"
                                label="Описание"
                            />
                        </Box>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    );
};
