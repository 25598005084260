import {
    List,
    Datagrid,
    TextField,
    EditButton,
    CreateButton,
    TopToolbar,
    BooleanField,
    DeleteButton
} from "react-admin";

const ListActions = () => {
    return (
        <TopToolbar>
            <CreateButton label="Создать" />
        </TopToolbar>
    )
}

export const StoryList = () => (
    <List exporter={false} actions={<ListActions />} sort={{}}>
        <Datagrid>
            <TextField label="#" source="id" />
            <TextField label="Заголовок" source="title" />
            <BooleanField label="Опубликовано" source="is_published" />
            <EditButton label="Редактировать"/>
            <DeleteButton label="Удалить" />
        </Datagrid>
    </List>
);
