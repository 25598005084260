import JwtManager from "./JwtManager";
import axiosRequest from "./axiosRequest";

export const refreshAuth = async () => {
    const refreshToken = async () => {
        await axiosRequest.post(JwtManager.getRefreshTokenEndpoint())
            .then((result) => {
                return result.data
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const token = JwtManager.getToken()
    if (token !== null) {

        if ((token.expires_in - 60) < Date.now() / 1000) {
            const token = await refreshToken();
            if (token !== undefined) {
                JwtManager.setToken(token)

                return token
            } else {
                return Promise.reject()
            }
        }
    }

    return Promise.resolve();
}
