import PersonIcon from "@mui/icons-material/Person";
import {Menu} from "react-admin";

export const CustomMenu = () => {
  return (
      <Menu>
          <Menu.Item to="/main" primaryText="Главная" leftIcon={<PersonIcon />}/>
          <Menu.Item to="/restaurant" primaryText="Рестораны" leftIcon={<PersonIcon />}/>
          <Menu.Item to="/filter" primaryText="Фильтры" leftIcon={<PersonIcon />}/>
          <Menu.Item to="/story" primaryText="Сторисы" leftIcon={<PersonIcon />}/>
          <Menu.Item to="/review" primaryText="Обзоры" leftIcon={<PersonIcon />}/>
          <Menu.Item to="/news" primaryText="Новости" leftIcon={<PersonIcon />}/>
          <Menu.Item to="/selection" primaryText="Подборки ресторанов" leftIcon={<PersonIcon />}/>
      </Menu>
  );
};
