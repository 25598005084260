import {
    ArrayInput,
    BooleanInput,
    CheckboxGroupInput,
    Create, DeleteButton,
    ImageField,
    ImageInput, NumberInput,
    ReferenceInput, SaveButton, SelectInput,
    SimpleForm, SimpleFormIterator,
    TextInput, Toolbar, useGetList, useInfiniteGetList
} from "react-admin";
import {Box, Grid, TextareaAutosize, Typography} from '@mui/material';
import {useEffect, useState} from "react";
import {convertFileToBase64} from "../helper";
import axiosRequest from "../axiosRequest";

export const FilterCreate = () => {

    const { data } = useGetList('filter/options')

    let options = []

    if (data) {
        options = data.map((item) => {
            return {
                id: item.name,
                name: item.value
            }
        })
    }

    const CreateToolbar = () => (
        <Toolbar>
            <SaveButton label="Создать" />
        </Toolbar>
    )

    return (
        <Create title="Создание фильтра">
            <SimpleForm toolbar={<CreateToolbar />}>
                <Grid container width="100%">
                    <Grid item>
                        <Typography variant="h6" gutterBottom>
                            Данные фильтра
                        </Typography>

                        <Box display="flex">
                            <Box flex={1} mr="0.5em">
                                <TextInput isRequired source="name" label="Название фильтра"/>
                            </Box>
                        </Box>

                        <Box display="flex">
                            <ArrayInput source="values" label="Значения фильтра">
                                <SimpleFormIterator inline>
                                    <TextInput source="name" label="Название значения фильтра" />
                                    <ArrayInput source="options" label="Опции значения">
                                        <SimpleFormIterator inline>
                                            <SelectInput source="name" label="Тип опции" choices={options} />
                                            <TextInput source="value" label="Значение" />
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Box>

                        <Box display="flex">
                            <BooleanInput name="is_fixable" source="is_fixable" label="Фиксированный фильтр"/>
                        </Box>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
};
