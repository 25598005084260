import {
    ArrayInput,
    BooleanInput, Edit,
    ImageField,
    ImageInput,
    SaveButton, SelectInput,
    SimpleForm, SimpleFormIterator,
    TextInput, Toolbar, useInfiniteGetList, FormDataConsumer
} from "react-admin";
import {
    RichTextInput
} from "ra-input-rich-text";
import {Box, Grid, Typography} from '@mui/material';
import {convertFileToBase64} from "../helper";
import { CustomDateTime } from "../components/CustomDateTime";
import { CustomNumberInput } from "../components/CustomNumberInput";

export const SelectionEdit = () => {
    const options = [
        { id: "lighting", name: "Молния" },
        { id: "fire", name: "Огонь" },
        { id: "camera", name: "Фотоаппарат" },
        { id: "hash", name: "Решетка" },
        { id: "star", name: "Звезда" },
        { id: "glass", name: "Стакан" },
        { id: "coffee", name: "Кофе" },
        { id: "heart", name: "Сердце" },
        { id: "bookmark", name: "Закладка" },
        { id: "tick", name: "Галочка" },
        { id: "point_on_map", name: "Точка на карте" },
        { id: "wineglass122", name: "Фужер" },
        { id: "roll142", name: "Суши" },
        { id: "taco", name: "Тако" },
        { id: "fork_and_knife", name: "Вилка и нож" },
        { id: "cake", name: "Торт" },
        { id: "pizza", name: "Пицца" },
        { id: "roll152", name: "Ролл" },
    ];

    let restaurants = [];

    const {
        data: restaurantData,
        hasNextPage: restaurantHasNextPage,
        isFetchingNextPage: restaurantFetchingNextPage,
        fetchNextPage: restaurantFetchNextPage
    } = useInfiniteGetList("restaurant", {
        pagination: { page: 1, perPage: 25 },
        sort: { field: 'name', order: 'ASC' }
    });

    const getRestaurants = () => {
        if (restaurantData) {
            restaurants = restaurantData.pages
                ?.map((page) => {
                    return page.data.filter((restaurant) => restaurant).map((restaurant) => {
                        return {
                            id: restaurant.id,
                            name: restaurant.name,
                        };
                    });
                }).flat()

            if (restaurantHasNextPage && !restaurantFetchingNextPage) {
                restaurantFetchNextPage();
            }
        }
    }

    getRestaurants()

    const CreateToolbar = () => (
        <Toolbar>
            <SaveButton label="Сохранить" />
        </Toolbar>
    );

    const transform = async (data) => {
        console.log("datadata", data);
        if (!!data?.photo_cover) {
            data.photo_cover.rawFile = await searchRawFile(data.photo_cover);
        }

        if (!!data?.restaurants) {
            for (let i = 0; i < data.restaurants.length; i++) {
                let dishes = data.restaurants[i].dishes;
                for (let j = 0; j < dishes.length; j++) {
                    if (!!data.restaurants[i]?.dishes[j]?.photo) {
                        data.restaurants[i].dishes[j].photo.rawFile = await searchRawFile(dishes[j].photo)
                    }
                }
            }
        }

        if (!!data?.restaurants) {
            for (let i = 0; i < data.restaurants.length; i++) {
                if (!!!data.restaurants[i]?.photos_interior || data.restaurants[i].photos_interior.length === 0) {
                    data.restaurants[i].photos_interior = [];
                    continue;
                }
                let photos_interior = data.restaurants[i].photos_interior;
                for (let j = 0; j < photos_interior.length; j++) {
                    if (!!data.restaurants[i]?.photos_interior[j]) {
                        data.restaurants[i].photos_interior[j].rawFile = await searchRawFile(photos_interior[j])
                    }
                }
            }
        }

        return data;
    };

    const searchRawFile = async (photo) => {
        if (!!photo?.src) {
            let response = await fetch(photo.src);
            let data = await response.blob();

            if (data.type === "application/xml") {
                return null;
            }

            photo.rawFile = new File([data], "", {
                type: data.type,
            });
        }

        if (!!photo?.rawFile && photo.rawFile instanceof File) {
            return await convertFileToBase64(photo.rawFile);
        }
    };

    return (
        <Edit mutationMode="pessimistic" transform={transform}>
            <SimpleForm toolbar={<CreateToolbar />}>

                    <Grid container width="100%">
                        <Grid item>
                            <Typography variant="h6" gutterBottom>
                                Общая информация
                            </Typography>

                            <Box display="flex">
                                <Box flex={1} mr="0.5em">
                                    <TextInput isRequired source="title" label="Название"/>
                                </Box>
                            </Box>

                            <Box display="flex">
                                <Box flex={1} mr="0.5em">
                                    <ImageInput isRequired source="photo_cover" label="Обложка"
                                                placeholder="Загрузите файл для обложки">
                                        <ImageField title="Обложка" source="src" />
                                    </ImageInput>

                                </Box>
                            </Box>

                            <Box display="flex">
                                <SelectInput
                                    isRequired
                                    label="Дополнительная обложка"
                                    source="additional_cover"
                                    sx={{ marginRight: '20px' }}
                                    choices={options}
                                />
                            </Box>

                                    {/* <ArrayInput label="Блюда" name="Блюда" source="dishes">
                                        <SimpleFormIterator inline>
                                            <TextInput label="Название блюда" source="name" />
                                            <NumberInput label="Цена блюда" source="price" />
                                            <ImageInput source="photo" label="Фото"
                                                        placeholder="Загрузите файл для фото">
                                                <ImageField title="Фото" source="src" />
                                            </ImageInput>
                                        </SimpleFormIterator>
                                    </ArrayInput> */}

                            <Box display="flex">
                                <Box flex={1} mr="0.5em">
                                    <TextInput
                                        source="content_title"
                                        label="Заголовок контента"
                                    />
                                </Box>
                            </Box>

                            <Box display="flex">
                                <RichTextInput
                                    sx={{ maxWidth: "1000px", maxHeight: "500px", "overflow-y": "auto"}}
                                    source="content_text"
                                    type="text"
                                    label="Текст"
                                ></RichTextInput>
                            </Box>

                            <Box display="flex">
                                {/* <DateTimeInput
                                    label="Дата и время публикации"
                                    isRequired
                                    source="publication_time"
                                /> */}
                                <CustomDateTime label="Дата и время публикации*:    " source="publication_time" />
                            </Box>

                            <Box display="flex">
                                <BooleanInput name="is_published" source="is_published" label="Опубликовано"/>
                            </Box>

                            <Box display="flex">
                                <Box flex={1} mr="0.5em">
                                    <TextInput
                                        source="author"
                                        label="Автор подборки"
                                    />
                                </Box>
                            </Box>

                            <Box display="flex">
                                <Box flex={1} mr="0.5em">
                                    <TextInput
                                        isRequired
                                        source="slug"
                                        label="URL"
                                    />
                                </Box>
                            </Box>

                            <Typography variant="h6" gutterBottom>
                                Рестораны
                            </Typography>

                            <Box display="flex">
                                <ArrayInput source="restaurants" label="Добавить ресторан">
                                    <SimpleFormIterator inline>
                                        <SelectInput
                                            isRequired
                                            source="restaurant_id"
                                            label="Выберите ресторан"
                                            choices={restaurants}
                                            sx={{ marginRight: '20px' }}
                                        />

                                        <RichTextInput
                                            sx={{ maxWidth: "1000px", maxHeight: "500px", "overflow-y": "auto"}}
                                            isRequired
                                            source="content_text_about"
                                            type="text"
                                            label="Текст"
                                        ></RichTextInput>

                                        <ImageInput
                                            isRequired
                                            source="photos_interior"
                                            label="Галерея фото"
                                            placeholder="Перетащите или загрузите фото"
                                            multiple={true}
                                        >
                                            <ImageField source="src" title="Галерея фото" />
                                        </ImageInput>

                                        <FormDataConsumer>
                                            {({
                                                  formData,
                                                scopedFormData,
                                                getSource
                                            }) => (
                                                <ArrayInput label="Блюда" name="Блюда" source={getSource("dishes")}>
                                                    <SimpleFormIterator inline disableAdd={scopedFormData?.dishes?.length >= 3}>
                                                        <TextInput label="Название блюда" source="name" />
                                                        <CustomNumberInput label="Цена блюда" source="price"/>
                                                        <ImageInput source="photo" label="Фото"
                                                                    placeholder="Загрузите файл для фото">
                                                            <ImageField title="Фото" source="src" />
                                                        </ImageInput>
                                                    </SimpleFormIterator>
                                                </ArrayInput>
                                            )}
                                        </FormDataConsumer>

                                        <RichTextInput
                                            sx={{ maxWidth: "1000px", maxHeight: "500px", "overflow-y": "auto"}}
                                            source="content_text_about2"
                                            type="text"
                                            label="Текст"
                                        ></RichTextInput>

                                        <ArrayInput
                                            label="Напитки"
                                            name="Напитки"
                                            source="dishes2"
                                        >
                                            <SimpleFormIterator inline>
                                                <TextInput
                                                    label="Название напитка"
                                                    source="name1"
                                                />
                                                <CustomNumberInput
                                                    label="Цена напитка"
                                                    source="price1"
                                                />
                                            </SimpleFormIterator>
                                        </ArrayInput>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Box>

                            <Typography variant="h6" gutterBottom>
                                SEO
                            </Typography>

                            <Box display="flex" flexDirection="column">
                                <TextInput source="seo_title" label="Заголовок" />
                                <TextInput
                                    source="seo_description"
                                    label="Описание"
                                />
                            </Box>
                        </Grid>
                    </Grid>
            </SimpleForm>
        </Edit>
    );
};
