import {
    ArrayInput,
    BooleanInput,
    CheckboxGroupInput,
    Create, DateTimeInput, DeleteButton, FormDataConsumer,
    ImageField,
    ImageInput, NumberInput,
    ReferenceInput, SaveButton, SelectArrayInput, SelectInput,
    SimpleForm, SimpleFormIterator,
    TextInput, Toolbar, useGetList, useGetMany, useInfiniteGetList
} from "react-admin";
import { Box, Grid, Typography } from "@mui/material";
import {Chip, Stack} from "@mui/material";

// const ListActions = () => {
//     return (
//         <TopToolbar>
//             <CreateButton label="Создать главную"/>
//         </TopToolbar>
//     )
// }



const QuickFilter = ({ label }) => {
    return <Chip sx={{ marginBottom: 1 }} label={label} />;
};

export const MainCreate = () => {
    const { data: restaurantData } = useInfiniteGetList("restaurant/published");
    const { data: articleData } = useInfiniteGetList("article");

    let restaurants = [];
    let articles = [];

    if (restaurantData) {
        restaurants = restaurantData.pages
            .map((page) => {
                return page.data.filter((restaurant) => restaurant.is_published).map((restaurant) => {
                    return {
                        id: restaurant.id,
                        name: restaurant.name,
                    };
                });
            })
            .flat();
    }

    const transform = async (data) => {}

    return (
        <Create title="Создание подборки" transform={transform}>
            <SimpleForm>
                <Grid container width="100%">
                    <Grid item>
                        <Typography variant="h6" gutterBottom>
                            Общая информация
                        </Typography>

                        <Box display="flex">
                            <Box flex={1} mr="0.5em">
                                <TextInput isRequired source="title" label="Название"/>
                            </Box>
                        </Box>

                        <Box display="flex">
                            <Box flex={1} mr="0.5em">
                                <TextInput
                                    source="content_title"
                                    label="Заголовок контента"
                                />
                            </Box>
                        </Box>

                        <Box display="flex">
                            <BooleanInput name="is_published" source="is_published" label="Опубликовано"/>
                        </Box>

                        <Box display="flex">
                            <Box flex={1} mr="0.5em">
                                <TextInput
                                    source="author"
                                    label="Автор подборки"
                                />
                            </Box>
                        </Box>

                        <Box display="flex">
                            <Box flex={1} mr="0.5em">
                                <TextInput
                                    source="slug"
                                    label="Slug"
                                />
                            </Box>
                        </Box>

                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
    // <List actions={<ListActions />}>
        // <Datagrid rowClick="edit">
        //     <TextField label="#" source="id" />
        //     <TextField label="Заголовок" source="title" />
        //     <BooleanField label="Статус публикации" source="is_published" valueLabelTrue="Да" valueLabelFalse="Нет" />
        //     <DateField label="Дата и время публикации" source="publication_date" />
        //     <EditButton label="Редактировать"/>
        //     <DeleteButton label="Удалить" />
        // </Datagrid>
    // </List>
};
