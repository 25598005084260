import {
    List,
    Datagrid,
    TextField,
    EditButton,
    CreateButton,
    TopToolbar,
    BooleanField,
    DateField,
    DeleteButton
} from "react-admin";
import {Chip, Stack} from "@mui/material";

const ListActions = () => {
    return (
        <TopToolbar>
            <CreateButton label="Добавить новость"/>
        </TopToolbar>
    )
}

const QuickFilter = ({ label }) => {
    return <Chip sx={{ marginBottom: 1 }} label={label} />;
};

export const NewsList = () => (
    <List actions={<ListActions />} sort={{ field: 'publication_time', order: 'DESC' }}>
        <Datagrid rowClick="edit">
            <TextField label="#" source="id" />
            <TextField label="Заголовок" source="title" />
            <BooleanField label="Статус публикации" source="is_published" valueLabelTrue="Да" valueLabelFalse="Нет" />
            <DateField label="Дата и время публикации" source="publication_time" />
            <EditButton label="Редактировать"/>
            <DeleteButton label="Удалить" />
        </Datagrid>
    </List>
);
