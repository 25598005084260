import {
    Edit,
    AutocompleteArrayInput,
    SimpleForm,
} from "react-admin";
import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from 'react';

export const MainEdit = () => {
    const [articles, setArticles] = useState([]);
    const [restaurants, setRestaurants] = useState([]);
    const [news, setNews] = useState([]);
    // const [selected, setSelected] = useState([]);

    useEffect(() => {
      async function fetchData() {
        const response = await fetch(process.env.REACT_APP_API_ENDPOINT + '/api/article/ids');
        const data = await response.json();
        setArticles(data);
      }
      fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
          const response = await fetch(process.env.REACT_APP_API_ENDPOINT + '/api/article/news_ids');
          const data = await response.json();
          setNews(data);
        }
        fetchData();
      }, []);

    useEffect(() => {
        async function fetchData() {
            const response = await fetch(process.env.REACT_APP_API_ENDPOINT + '/api/restaurant/ids');
            const data = await response.json();
            setRestaurants(data);
        }
        fetchData();
    }, []);

    // useEffect(() => {
    //     async function fetchData() {
    //         const response = await fetch(process.env.REACT_APP_API_ENDPOINT + '/api/main');
    //         const data = await response.json();
    //         setSelected(data);
    //     }
    //     fetchData();
    // }, []);

    let articleChoices = [];
    if (articles) {
        articleChoices = articles.map((value, index) => {
            const id = value.toString();
            const name = value.toString();
            return { id, name };
        });
    }


    let newsChoices = [];
    if (news) {
        newsChoices = news.map((value, index) => {
            const id = value.toString();
            const name = value.toString();
            return { id, name };
        });
    }


    let restaurantsChoices = [];
    if (restaurants?.data) {
        restaurantsChoices = restaurants.data.map((value, index) => {
            const id = value.id;
            const name = value.name;
            return { id, name };
        });
    }

    return (
        <Edit mutationMode="pessimistic">
            <SimpleForm>
                <Grid container width="100%">
                    <Grid item>
                        <Typography variant="h6" gutterBottom>
                            Главная страница
                        </Typography>

                        <AutocompleteArrayInput source="news" label="Новости" choices={newsChoices} />
                        <AutocompleteArrayInput source="restaurants" label="Рестораны" choices={restaurantsChoices} />
                        <AutocompleteArrayInput source="articles" label="Статьи" choices={articleChoices} />

                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    )
};
