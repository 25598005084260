import axios from 'axios';
import JwtManager from './JwtManager';

const jwtInterceptor = axios.create({
    withCredentials: true
});

jwtInterceptor.interceptors.request.use((config) => {
    const token = JwtManager.getToken()
    config.headers['Access-Control-Allow-Origin'] = `*`;
    config.headers['Authorization'] = `bearer ${token.access_token}`;
    return config;
});

jwtInterceptor.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const prevRequest = error.config;
        if (error?.response?.status === 401 && !prevRequest?.sent) {
            JwtManager.eraseToken();
            return Promise.reject(error);
        }
        return Promise.reject(error);
    },
);

export default jwtInterceptor;
